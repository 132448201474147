<template>
  <div class="app-container">
    <div class="filter-container">

    </div>
    <div class="filter-container">
      <el-input class="filter-item" v-model="listQuery.keyword" placeholder="请输入登录名/姓名/手机号码" style="width: 260px;" clearable/>
      <el-select class="filter-item" v-model="listQuery.type" placeholder="账号类型" style="width: 140px" clearable>
        <el-option v-if="user.type === 'admin'" label="平台管理员" value="admin"></el-option>
        <el-option label="学校管理员" value="school"></el-option>
        <el-option label="学校监管" value="school_supervisor"></el-option>
        <el-option label="食堂监管" value="canteen_supervisor"></el-option>
        <el-option label="店铺管理员" value="shop"></el-option>
      </el-select>
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-edit" @click="handleCreate">添加</el-button>
    </div>

    <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
      <el-table-column align="center" label="ID" width="60">
        <template slot-scope="scope">
          {{ scope.row.id }}
        </template>
      </el-table-column>
      <el-table-column label="登录名" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.username }}
        </template>
      </el-table-column>
      <el-table-column label="姓名" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.name }}
        </template>
      </el-table-column>
      <el-table-column label="手机号码" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.phone }}
        </template>
      </el-table-column>
      <el-table-column label="账号类型" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.type == 'admin'" type="primary">平台管理员</el-tag>
          <el-tag v-else-if="scope.row.type == 'school'" type="info">学校管理员</el-tag>
          <el-tag v-else-if="scope.row.type == 'school_supervisor'" type="info">学校监管</el-tag>
          <el-tag v-else-if="scope.row.type == 'canteen_supervisor'" type="info">食堂监管</el-tag>
          <el-tag v-else-if="scope.row.type == 'shop'" type="info">店铺管理员</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="created_at" label="创建时间" min-width="200">
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ scope.row.created_at }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" align="center" width="180" class-name="small-padding fixed-width">
        <template v-if="scope.row.id != 1" slot-scope="scope">
          <el-row>
            <el-button type="primary" size="mini" @click="handleEdit(scope.row)">编辑</el-button>
            <el-button type="danger" size="mini" @click="handleDel(scope.row.id)">删除</el-button>
          </el-row>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>

    <el-dialog :visible.sync="dialogFormVisible" :close-on-click-modal="false" width="50%">
      <el-form ref="form" :model="form" label-position="left" label-width="70px">
        <el-form-item label="账号类型">
          <el-select :disabled="form.id ? true : false" v-model="form.type" placeholder="账号类型" style="width: 140px" clearable>
            <el-option v-if="user.user.type === 'admin'" label="平台管理员" value="admin"></el-option>
            <el-option label="学校管理员" value="school"></el-option>
            <el-option label="学校监管" value="school_supervisor"></el-option>
            <el-option label="食堂监管" value="canteen_supervisor"></el-option>
            <el-option label="店铺管理员" value="shop"></el-option>
          </el-select>
          <el-select :disabled="form.id ? true : false" v-if="form.type == 'school' || form.type == 'school_supervisor' || form.type == 'canteen_supervisor' || form.type == 'shop'" v-model="form.school_id" placeholder="请选择学校" style="width: 160px">
            <el-option v-for="item in schools" :key="item.id" :label="item.school_name" :value="item.id">
            </el-option>
          </el-select>
          <el-select :disabled="form.id ? true : false" v-if="form.type == 'canteen_supervisor'" v-model="form.canteen_id" placeholder="请选择食堂" style="width: 160px">
            <el-option v-for="item in canteens" :key="item.id" :label="item.canteen_name" :value="item.id">
            </el-option>
          </el-select>
          <el-select :disabled="form.id ? true : false" v-if="form.type == 'shop'" v-model="form.shop_id" placeholder="请选择店铺" style="width: 160px">
            <el-option v-for="item in shops" :key="item.id" :label="item.shop_name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="登录名">
          <el-input v-model="form.username" placeholder="请输入用户名" />
        </el-form-item>
        <el-form-item label="登录密码">
          <el-input v-model="form.password" placeholder="请输入登录密码，不填则保持原密码不变" />
        </el-form-item>
        <el-form-item label="姓名">
          <el-input v-model="form.name" placeholder="请输入姓名" />
        </el-form-item>
        <el-form-item label="手机号码">
          <el-input v-model="form.phone" placeholder="请输入手机号码" />
        </el-form-item>
        <el-form-item label="权限" prop="permissions">
          <el-checkbox :disabled="permissionDisabled" :indeterminate="isIndeterminatePermissions" v-model="checkAllPermissions" @change="handleCheckAllPermissionsChange">全选</el-checkbox>
          <div style="margin: 15px 0;"></div>
          <el-checkbox-group v-model="form.permissions">
            <el-checkbox :disabled="permissionDisabled" v-for="permission in allPermissions" :label="permission.id" :key="permission.id">{{permission.display_name}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      canteens: [],
      shops: [],
      list: null,
      total: null,
      listLoading: true,
      btnLoading: false,
      listQuery: {
        page: 1,
        limit: 10,
        keyword: "",
        type: "",
        school_id: "",
      },
      dialogFormVisible: false,
      pvData: [],
      form: {
        id: "",
        username: "",
        name: "",
        phone: "",
        password: "",
        type: "",
        permissions: [],
        school_id: "",
        canteen_id: "",
        shop_id: ""
      },
      //权限复选框
      checkAllPermissions: false,
      isIndeterminatePermissions: false,
      allPermissions: [],
      //按钮提交loading
      btnLoading: false
    };
  },
  created() {
    this.getList();
    this.getPermissionAll();
  },
  watch: {
    school_id() {
      this.getList()
    },
    "form.type": {
      handler(newValue, oldValue) {
        // 编辑时无需重置选项
        if (this.form.id) {
          return;
        }
        this.form.school_id = "";
        this.form.canteen_id = "";
        this.form.shop_id = "";
        if (newValue == "admin") {
          this.form.permissions = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22];
        } else if (newValue == "school") {
          this.form.permissions = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22];
        } else if (newValue == "school_supervisor") {
          this.form.permissions = [2,14];
        } else if (newValue == "canteen_supervisor") {
          this.form.permissions = [2];
        } else if (newValue == "shop") {
          this.form.permissions = [2];
        }
      }
    },
    "form.permissions": {
      handler(newValue, oldValue) {
        let checkedCount = newValue.length;
        this.checkAllPermissions = checkedCount === this.allPermissions.length;
        this.isIndeterminatePermissions =
          checkedCount > 0 && checkedCount < this.allPermissions.length;
      }
    },
    "form.school_id": {
      handler(newValue, oldValue) {
        // 编辑时无需重置选项
        if (!this.form.id) {
          this.canteens = [];
          this.shops = [];
          this.form.canteen_id = "";
          this.form.shop_id = "";
          if (newValue === "") {
            return;
          }
        }
        if (this.form.type == "canteen_supervisor") {
          this.getCanteenList();
        } else if (this.form.type == "shop") {
          this.getShopList();
        }
      }
    },
    dialogFormVisible(newVal, oldVal) {
      // 编辑框隐藏，马上清除旧数据
      if (newVal === false) {
        this.form = {
          id: "",
          username: "",
          name: "",
          phone: "",
          password: "",
          type: "",
          permissions: [],
          school_id: "",
          canteen_id: "",
          shop_id: ""
        };
        this.checkAllPermissions = false;
        this.isIndeterminatePermissions = false;
      }
    }
  },
  computed: {
    ...mapGetters(["schools", "school_id","user"]),
    permissionDisabled() {
      return false;
      // return this.form.type == "school" || this.form.type == "canteen" || this.form.type == "shop"
    }
  },
  methods: {
    getList() {
      this.listLoading = true;
      this.listQuery.school_id = this.school_id
      request({
        url: "/api/backend/admin/list",
        method: "get",
        params: this.listQuery
      }).then(response => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    getCanteenList() {
      this.canteens = [];
      request({
        url: "/api/backend/canteen/list",
        method: "get",
        params: {
          page: 0,
          limit: 1000000,
          school_id: this.form.school_id
        }
      }).then(response => {
        this.canteens = response.data.data;
      });
    },
    getShopList() {
      this.shops = [];
      request({
        url: "/api/backend/shop/list",
        method: "get",
        params: {
          page: 1,
          limit: 1000000,
          school_id: this.form.school_id
        }
      }).then(response => {
        this.shops = response.data.data;
      });
    },
    getPermissionAll() {
      request({
        url: "/api/backend/permission/all",
        method: "get",
        params: {}
      }).then(response => {
        this.allPermissions = response.data;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleCreate() {
      this.dialogFormVisible = true;
    },
    handleEdit(row) {
      let permissions = [];
      for (let i = 0; i < row.permissions.length; i++) {
        permissions.push(row.permissions[i].id);
      }
      this.form = {
        id: row.id,
        username: row.username,
        name: row.name,
        phone: row.phone,
        password: "",
        type: row.type,
        permissions: permissions,
        school_id: row.school_id,
        canteen_id: row.canteen_id,
        shop_id: row.shop_id
      };
      this.dialogFormVisible = true;
    },
    saveData() {
      if (!this.form.type) {
        this.$message({
          type: "warning",
          message: "请选择账号类型"
        });
        return;
      }
      if (!this.form.username) {
        this.$message({
          type: "warning",
          message: "请输入登录名"
        });
        return;
      }
      if (!this.form.id && !this.form.password) {
        this.$message({
          type: "warning",
          message: "请输入登录密码"
        });
        return;
      }
      if (this.form.permissions.length <= 0) {
        this.$message({
          type: "warning",
          message: "请选择权限"
        });
        return;
      }
      this.btnLoading = true;
      request({
        url: "/api/backend/admin/save",
        method: "post",
        data: this.form
      })
        .then(response => {
          this.btnLoading = false;
          this.dialogFormVisible = false;
          this.$notify({
            title: "成功",
            message: "保存成功",
            type: "success",
            duration: 2000
          });
          this.getList();
        })
        .catch(err => {
          this.btnLoading = false;
        });
    },
    handleDel(val) {
      this.$confirm("此操作将删除该用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          request({
            url: "/api/backend/admin/del",
            method: "get",
            params: { id: val }
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "删除成功!"
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    handleCheckAllPermissionsChange(val) {
      let checkedPermissions = [];
      if (val) {
        for (let i = 0; i < this.allPermissions.length; i++) {
          checkedPermissions.push(this.allPermissions[i].id);
        }
      }
      this.form.permissions = checkedPermissions;
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss">
form .el-checkbox {
  margin-right: 20px;
}
.el-checkbox + .el-checkbox {
  margin-left: 0;
}
</style>
<style rel="stylesheet/scss" lang="scss" scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
</style>
